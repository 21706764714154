import * as Yup from "yup";
import { validateCpf } from "./customValidations";
import { luhnValidation, luhnCheck } from "../helpers/CreditCardHelper";
import DateHelper from "../helpers/DateHelper";
import StringHelper from "../helpers/StringHelpers";

// Regex
const phoneRegex = /^\(?(\d{2})\)?[-\s]?(\d{4,5})[-\s]?(\d{4})$/g;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>\s]{8,}$/;
const specialCharRegex = /^[A-Za-záàâãéèêíïóôõöúüçñÁÀÂÃÉÈÍÏÓÔÕÖÚÜÇÑ\s]*$/;

// Yup validations
function validateCreditCard() {
  return this.test({
    name: "validateCreditCard",
    exclusive: false,
    message: "fields.creditCard",
    test: function (value) {
      if (value) {
        return luhnValidation(value) || luhnCheck(value);
      }
      return false;
    }
  });
}
function validadeDateOfBirth() {
  return this.test({
    name: "validadeDateOfBirth",
    exclusive: false,
    message: "fields.dateOfBirth",
    test: function (value) {
      if (value && value.length >= 9) {
        const _value = value.replace(/(\d{2})(\d{4})$/g, "$1/$2");
        const birthDate = DateHelper.getObjCustomDate(_value);
        const dateNow = DateHelper.getObjDateNow();
        let age = dateNow.year - birthDate.year;
        const month = dateNow.mounth - birthDate.mounth;
        if (month < 0 || (month === 0 && dateNow.day < birthDate.day)) {
          age--;
        }
        return age >= 18;
      }
    }
  });
}
function validateCpfCnpj() {
  return this.test({
    name: "validateCpfCnpj",
    exclusive: false,
    message: "fields.cpfCnpj",
    test: function (value) {
      const _value = StringHelper.onlyDigits(value);
      if (_value && _value.length < 14) {
        return validateCpf(_value);
      }
      // return validateCnpj(_value);
    },
  });
}
function equalTo(ref, msg) {
	return this.test({
    name: "equalTo",
    exclusive: false,
    message: msg || "fields.passwordConfirm",
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);
Yup.addMethod(Yup.string, "validadeDateOfBirth", validadeDateOfBirth);
Yup.addMethod(Yup.string, "validateCpfCnpj", validateCpfCnpj);
Yup.addMethod(Yup.string, "validateCreditCard", validateCreditCard);

const required = (msg = "fields.required") => Yup.string().required(msg);
const password = required().matches(passwordRegex, "fields.password");
const phone = Yup.string().matches(phoneRegex, "fields.cellPhone");
const email = Yup.string().email("fields.email");
const confirmPassword = Yup.string()
  .required("fields.required")
  .equalTo(Yup.ref("password"));
const cpfCnpj = required().validateCpfCnpj();
const creditCard = required().validateCreditCard();
const dateOfBirth = required().validadeDateOfBirth();

export const ModalSignUpSchema = Yup.object({
  name: required(),
  email: email.required("fields.required"),
  phone: phone.required("fields.required"),
});

export const RegisterSchema = Yup.object({
  fullname:  required().matches(specialCharRegex, "fields.specialChar").matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "fields.name"),
  email: email.required("fields.required"),
  password: password,
  confirmPassword: confirmPassword,
  phoneNumber: phone.required("fields.required"),
});

export const ChangePasswordSchema = Yup.object({
  password: password,
  confirmPassword: confirmPassword
});

export const CheckoutSchema = ({ isLoggedIn, isFree, isBrazilian }) => {
  const CheckoutSchema = Yup.object({
    email: email.required("fields.required"),
    username: required().matches(specialCharRegex, "fields.specialChar"),
    usersurname: required().matches(specialCharRegex, "fields.specialChar"),
    document: isBrazilian && cpfCnpj,
    dateOfBirth: dateOfBirth,
    cellphone: phone.required("fields.required"),
    cep: !isFree && required(),
    address: !isFree && required(),
    number: !isFree && required(),
    neighborhood: !isFree && required(),
    city: !isFree && required(),
    state: !isFree && required(),
  });
  const CheckoutFullSchema = Yup.object({
    email: email.required("fields.required"),
    username: required().matches(specialCharRegex, "fields.specialChar").matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "fields.name"),
    document: isBrazilian && cpfCnpj,
    dateOfBirth: dateOfBirth,
    cellphone: phone.required("fields.required"),
    cep: !isFree && required(),
    address: !isFree && required(),
    number: !isFree && required(),
    neighborhood: !isFree && required(),
    city: !isFree && required(),
    state: !isFree && required(),
    password: password,
    confirmPassword: confirmPassword,
  });
  if (isLoggedIn) {
    return CheckoutSchema;
  } else {
    return CheckoutFullSchema;
  }
}
export const PaymentSchema = (hasUserCreditCard) => {
  const PaymentFullSchema = Yup.object({
    creditCardNumber: creditCard,
    holder: required("fields.creditCardHolder").matches(
      specialCharRegex,
      "fields.specialChar"
    ),
    cardValidity: required().min(5, "fields.cardValidity"),
    securityNumber: required().min(3, "fields.securityNumber"),
    numberOfInstallments: required(),
  });
  const PaymentSchema = Yup.object({
    numberOfInstallments: required(),
  });

  if (hasUserCreditCard) {
    return PaymentSchema;
  } else {
    return PaymentFullSchema;
  }
}

export const LoginSchema = Yup.object({
  userEmail: required("fields.required"),
  userPassword: required("fields.required"),
});

export const LeadSchema = Yup.object({
  name: required("fields.required"),
  surname: required("fields.required"),
  phoneNumber: phone.required("fields.required"),
  company: required("fields.required"),
  email: required("fields.required"),
});

export default ModalSignUpSchema;
