import Logger from "../modules/logger";
import StorageHelper from "./StorageHelper";

const _caninsiderTrack = () => {
  return StorageHelper.getFromLocalStorage('envProduct') === 'INVESTACADEMY';
}

const _trackInsiderNavegation = (insiderType, insiderUser, insiderProduct) => {
  window.insider_object = window.insider_object || {};
  window.insider_object.page = {
    type: insiderType,
  };

  if (insiderProduct) window.insider_object.product = insiderProduct;
  if (insiderUser) window.insider_object.user = insiderUser;

  window.Insider.eventManager.dispatch('init-manager:re-initialize');
};

const _trackInsiderConfirmation = (transactionObj) => {
  window.insider_object = window.insider_object || {};
  window.insider_object.page = window.insider_object.page || {};

  if (transactionObj) window.insider_object.transaction = transactionObj;

  if (window.insider_object.page.type === 'Confirmation') return;

  window.insider_object.page = {
    type: 'Confirmation',
  };

  window.Insider.eventManager.dispatch('init-manager:re-initialize');
};

const _trackInsiderEvent = (eventInfos) => {
  window.Insider.track('events', [eventInfos]);
};

const _deleteInsiderParams = () => {
  if (window.insider_object) {
    delete window.insider_object.product;
    delete window.insider_object.transaction;
  }
};

export const UserTrackingHelperHelper = {
  trackNavegation(insiderType = 'Other', insiderUser = null, insiderProduct = null) {
    _deleteInsiderParams();

    if (_caninsiderTrack()) {
      try {
        _trackInsiderNavegation(insiderType, insiderUser, insiderProduct);
      } catch (error) {
        Logger.doLog('ERROR', 'INSIDER.TRACK - Error to track user navegation', { error: String(error) });
      }
    }
  },

  trackPurchase(transactionObj = null) {
    _deleteInsiderParams();

    if (_caninsiderTrack()) {
      try {
        _trackInsiderConfirmation(transactionObj);
      } catch (error) {
        Logger.doLog('ERROR', 'INSIDER.TRACK - Error to track user confirmation', { error: String(error) });
      }
    }
  },

  trackEvent(eventInfos) {
    _deleteInsiderParams();

    if (_caninsiderTrack()) {
      try {
        _trackInsiderEvent(eventInfos);
      } catch (error) {
        Logger.doLog('ERROR', 'INSIDER.TRACK - Error to track event', { error: String(error) });
      }
    }
  },

  getInsiderType(route) {
    if(route === '' || route === '/' || route === '/home') return 'Home';
    else if (route.includes('all-courses')) return 'Category';
    else if (route.includes('courselp')) return 'Product';
    else if (route.includes('checkout/sign-in') || route.includes('checkout/sign-up')) return 'Checkout';
    
    return 'Other';
  }
};

export default UserTrackingHelperHelper;
